import React from "react";
import ManageService from "../../components/ManageService";
import Seo from "../../components/seo";

const BudgetPage = (props) => (
  <>
    <Seo title="Budget Dashboard" />
    <ManageService {...props} />
  </>
);

export default BudgetPage;
