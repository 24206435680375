import React from "react";
import data from "../../constants/manageServices";
import {
  Container,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
const ManageService = () => {
  return (
    <Container className="dashboard">
      <Paper className="container managed-service">
        <h1>Managed Service</h1>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Line Manager</b>
              </TableCell>
              <TableCell>
                <b>Vendor</b>
              </TableCell>
              <TableCell>
                <b>Contact Period</b>
              </TableCell>
              <TableCell>
                <b>Charged In</b>
              </TableCell>
              <TableCell>
                <b>Projects</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((i, index) => (
              <TableRow key={index}>
                <TableCell>{i.Name}</TableCell>
                <TableCell>{i["Line manager"]}</TableCell>
                <TableCell>{i.Vendor}</TableCell>
                <TableCell>{i["Period contract"]}</TableCell>
                <TableCell>{i["Charge in"]}</TableCell>
                <TableCell className="break">{i.projects.join('\r\n')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default ManageService;
