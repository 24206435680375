const MANAGE_SERVICE = [
  {
    Name: "Jon Smith",
    Position: "Dev",
    "Line manager": "Lotus",
    Vendor: "test",
    Salary: 100.0,
    "Period contract": 9.0,
    "Total(Exc Vat)": 100.0,
    "Charge in": "Monitoring",
    "Phone no.": "xxx",
    "E-mail": "xxx@gmail.com",
    projects: ["[114] Monitoring"],
  },
  {
    Name: "Alan Dee",
    Position: "Dev",
    "Line manager": "Lotus",
    Vendor: "test",
    Salary: 100.0,
    "Period contract": 9.0,
    "Total(Exc Vat)": 100.0,
    "Charge in": "MY/O2O",
    "Phone no.": "xxx",
    "E-mail": "xxx@gmail.com",
    projects: ["[115] E-Invoice (MY)"],
  },
  
];

export default MANAGE_SERVICE;
